// export class WebSocketHandler {
//     url: string;
//     socket: WebSocket | null; // Allow 'socket' to be either 'WebSocket' or 'null'
//     eventListeners: {
//         open: Array<(event: Event) => void>;
//         close: Array<(event: CloseEvent) => void>;
//         error: Array<(error: Event) => void>;
//         message: Array<(data: MessageEvent) => void>;
//     };

//     constructor(url: string) {
//         this.url = url;
//         this.socket = null; // Initially, the socket is null
//         this.eventListeners = {
//             open: [],
//             close: [],
//             error: [],
//             message: [],
//         };
//     }

//     connect() {
//         if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
//             console.warn("WebSocket is already open or connecting.");
//             return;
//         }

//         console.log("Attempting to connect to WebSocket at:", this.url);
//         this.socket = new WebSocket(this.url);

//         this.socket.onopen = (event: Event) => {
//             console.log("WebSocket connected");
//             this._emitEvent("open", event);
//         };

//         this.socket.onclose = (event: CloseEvent) => {
//             console.log("WebSocket disconnected", event);
//             console.log("Was Clean:", event.wasClean);
//             console.log("Code:", event.code);
//             console.log("Reason:", event.reason);
//             this._emitEvent("close", event);
//         };

//         this.socket.onerror = (error: Event) => {
//             console.error("WebSocket error:", error);
//             this._emitEvent("error", error);
//             this._debugSSLError();
//         };

//         this.socket.onmessage = (event: MessageEvent) => {
//           try {
//             const jsonData = JSON.parse(event.data); // Attempt to parse JSON
//             console.log("JSON Message received:", jsonData);
//             this._emitEvent("message", jsonData); // Emit the parsed JSON
//           } catch (e) {
//             console.warn("Non-JSON message received. Passing raw data:", event.data);
//             this._emitEvent("message", event.data); // Emit the raw message
//         }
//       };
//     }

//     sendMessage(message: string) {
//         if (this.socket && this.socket.readyState === WebSocket.OPEN) {
//             console.log("Sending message:", message);
//             this.socket.send(message);
//         } else {
//             console.error("WebSocket is not open. Cannot send message.");
//         }
//     }

//     close() {
//         if (this.socket) {
//             console.log("Closing WebSocket connection...");
//             this.socket.close();
//         } else {
//             console.warn("No active WebSocket connection to close.");
//         }
//     }

//     // Add an event listener for a specific event
//     on(eventType: keyof WebSocketHandler["eventListeners"], callback: (data: any) => void) {
//     if (this.eventListeners[eventType]) {
//         console.log(`Adding listener for '${eventType}' event.`);
//         this.eventListeners[eventType].push(callback);
//     } else {
//         console.error(`Invalid event type: ${eventType}`);
//     }
// }

//     // Remove all listeners for a specific event type
//     off(eventType: keyof WebSocketHandler["eventListeners"]) {
//         if (this.eventListeners[eventType]) {
//             this.eventListeners[eventType] = [];
//         } else {
//             console.error(`Invalid event type: ${eventType}`);
//         }
//     }

//     // Private method to emit an event to all listeners
//     private _emitEvent(eventType: keyof WebSocketHandler["eventListeners"], data: any) {
//     if (this.eventListeners[eventType]) {
//         console.log(`Emitting '${eventType}' event to`, this.eventListeners[eventType].length, "listeners with data:", data);
//         this.eventListeners[eventType].forEach((callback) => callback(data));
//     } else {
//         console.error(`No listeners found for event type: ${eventType}`);
//     }
// }

//     private _debugSSLError() {
//         console.warn(
//             "If you are encountering SSL issues, ensure the following:\n" +
//                 "1. The certificate is valid and trusted.\n" +
//                 "2. You are using 'wss://' for secure connections.\n" +
//                 "3. For local development, trust the certificate or use 'ws://'."
//         );
//     }
// }



// Code usage sample

// const socketHandler = new WebSocketHandler("ws://localhost:5050");

// // Connect to the WebSocket server
// socketHandler.connect();

// // Listen for events
// socketHandler.on("open", () => console.log("Connection opened"));
// socketHandler.on("message", (data) => console.log("Message received:", data));
// socketHandler.on("close", () => console.log("Connection closed"));
// socketHandler.on("error", (error) => console.error("Error occurred:", error));

// // Send a message
// socketHandler.sendMessage("Hello, WebSocket!");



//  UPDATED WEBSOCKETHANDLER CLASS
export class WebSocketHandler {
    url: string;
    socket: WebSocket | null;
    eventListeners: {
        open: Array<(event: Event) => void>;
        close: Array<(event: CloseEvent) => void>;
        error: Array<(error: Event) => void>;
        message: Array<(data: any) => void>;
    };
    isConnecting: boolean;
    reconnectInterval: number;
    maxReconnectInterval: number;
    reconnectAttempts: number;
    reconnectTimeout: any;
    heartbeatInterval: any;

    constructor(url: string) {
        this.url = url;
        this.socket = null;
        this.isConnecting = false;
        this.reconnectInterval = 1000; // Initial reconnect delay in ms
        this.maxReconnectInterval = 30000; // Max delay between reconnect attempts
        this.reconnectAttempts = 0;
        this.reconnectTimeout = null;
        this.heartbeatInterval = null;
        this.eventListeners = {
            open: [],
            close: [],
            error: [],
            message: [],
        };

        // Handle online/offline events
        window.addEventListener("online", () => this._handleOnline());
        window.addEventListener("offline", () => this._handleOffline());
    }

    connect() {
        if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.isConnecting)) {
            console.warn("WebSocket is already open or connecting.");
            return;
        }

        this.isConnecting = true;
        console.log("Attempting to connect to WebSocket at:", this.url);
        this.socket = new WebSocket(this.url);

        this.socket.onopen = (event: Event) => {
            console.log("WebSocket connected");
            this.isConnecting = false;
            this.reconnectAttempts = 0;
            this.reconnectInterval = 1000; // Reset reconnect delay
            this._emitEvent("open", event);

            // Start the heartbeat mechanism
            this._startHeartbeat();
        };

        this.socket.onclose = (event: CloseEvent) => {
            console.log("WebSocket disconnected", event);
            this.isConnecting = false;
            this._emitEvent("close", event);

            // Reconnect if not closed intentionally
            if (!event.wasClean) {
                console.log("Attempting to reconnect...");
                this._scheduleReconnect();
            }

            // Stop heartbeat
            this._stopHeartbeat();
        };

        this.socket.onerror = (error: Event) => {
            console.error("WebSocket error:", error);
            this.isConnecting = false;
            this._emitEvent("error", error);

            // Attempt reconnection
            this._scheduleReconnect();
        };

        this.socket.onmessage = (event: MessageEvent) => {
            try {
                const jsonData = JSON.parse(event.data);
                console.log("JSON Message received:", jsonData);
                this._emitEvent("message", jsonData);
            } catch (e) {
                console.warn("Non-JSON message received. Passing raw data:", event.data);
                this._emitEvent("message", event.data);
            }
        };
    }

    sendMessage(message: string) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            console.log("Sending message:", message);
            this.socket.send(message);
        } else {
            console.error("WebSocket is not open. Cannot send message.");
        }
    }

    close() {
        if (this.socket) {
            console.log("Closing WebSocket connection...");
            this.socket.close();
        } else {
            console.warn("No active WebSocket connection to close.");
        }
    }

    on(eventType: keyof WebSocketHandler["eventListeners"], callback: (data: any) => void) {
        if (this.eventListeners[eventType]) {
            console.log(`Adding listener for '${eventType}' event.`);
            this.eventListeners[eventType].push(callback);
        } else {
            console.error(`Invalid event type: ${eventType}`);
        }
    }

    off(eventType: keyof WebSocketHandler["eventListeners"]) {
        if (this.eventListeners[eventType]) {
            this.eventListeners[eventType] = [];
        } else {
            console.error(`Invalid event type: ${eventType}`);
        }
    }

    private _emitEvent(eventType: keyof WebSocketHandler["eventListeners"], data: any) {
        if (this.eventListeners[eventType]) {
            console.log(`Emitting '${eventType}' event to`, this.eventListeners[eventType].length, "listeners with data:", data);
            this.eventListeners[eventType].forEach((callback) => callback(data));
        }
    }

    private _scheduleReconnect() {
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }

        this.reconnectTimeout = setTimeout(() => {
            this.reconnectAttempts++;
            this.reconnectInterval = Math.min(this.reconnectInterval * 2, this.maxReconnectInterval); // Exponential backoff
            console.log(`Reconnecting attempt #${this.reconnectAttempts}...`);
            this.connect();
        }, this.reconnectInterval);
    }

    private _handleOnline() {
        console.log("Browser is online. Reconnecting WebSocket...");
        this.connect();
    }

    private _handleOffline() {
        console.warn("Browser is offline. WebSocket connection paused.");
        if (this.socket) {
            this.socket.close();
        }
    }

    private _startHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
        }

        this.heartbeatInterval = setInterval(() => {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                console.log("Sending heartbeat...");
                this.socket.send(JSON.stringify({ type: "ping" }));
            }
        }, 30000); // Send a ping every 30 seconds
    }

    private _stopHeartbeat() {
        if (this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = null;
        }
    }
}
